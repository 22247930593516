<template>
  <div>
    <div v-if="isValid" class="h-100 mx-0 d-flex">
      <div
        class="p-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center position-relative"
      >
        <img
          class="mx-auto logo position-absolute"
          width="148px"
          height="56px"
          :srcset="require('@/assets/img/logo-orange-base.svg?srcset')"
        />
        <div class="wrap-banner d-flex flex-column w-100 h-100">
          <div
            class="banner-container position-relative d-flex justify-content-center align-items-end h-100"
          >
            <img
              class="d-block banner"
              :srcset="
                require('@/assets/img/login/web_login_banner.png?srcset')
              "
            />
          </div>
        </div>
        <div class="app-title text-center px-5 py-4">
          <div class="title text-32 font-weight-bold mb-1">
            {{ $t('註冊成功') }}
          </div>
          <div class="text-20 font-weight-bold">
            {{ $t('開始你的圍棋學習之旅') }}！
          </div>
          <b-button
            v-if="isPhoneBrowser"
            variant="primary"
            class="mx-auto mt-4 d-flex align-items-center justify-content-center"
            size="md"
            @click="goAppStore"
            ><span class="icon-Download mr-1 text-18"></span
            >{{ $t('下載 APP') }}</b-button
          >
          <b-button
            v-if="isPhoneBrowser"
            variant="secondary"
            class="mx-auto mt-4"
            size="md"
            @click="goLogin"
            >{{ $t('返回登入頁面') }}</b-button
          >
          <b-button
            v-if="!isPhoneBrowser"
            variant="primary"
            class="mx-auto mt-4"
            size="md"
            @click="goLogin"
            >{{ $t('自動跳轉登入頁面') }}({{ debounceSec }})</b-button
          >
        </div>
      </div>
    </div>
    <div v-else>
      <modal-confirm
        v-model="isRedirectModalShow"
        :confirm-text="$t('知道了')"
        :hide-cancel="true"
        @confirm="goLogin"
      >
        {{ $t('此驗證信時效已到期，將幫您導回登入頁。') }}
      </modal-confirm>
    </div>
  </div>
</template>

<script>
import {Device} from '@capacitor/device';
import fbTrack from '@/lib/base/fbTrack';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';

export default {
  components: {
    ModalConfirm,
  },
  data() {
    return {
      isRedirectModalShow: false,
      debounceSec: 5,
      isValid: false,
      deviceInfo: null,
      winRef: null,
    };
  },
  computed: {
    viewMode() {
      return this.$store.state.env.viewMode;
    },
    device() {
      return this.$store.state.env.device;
    },
    isPhoneBrowser() {
      return (
        this.deviceInfo &&
        this.deviceInfo.platform === 'web' &&
        this.viewMode !== 'web'
      );
    },
    type() {
      return this.$route.query.type;
    },
    code() {
      return this.$route.query.code;
    },
    id() {
      return this.$route.query.id;
    },
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
  },
  async created() {
    this.deviceInfo = await Device.getInfo();
  },
  mounted() {
    if (this.type === 'phone') {
      this.isValid = true;
      fbTrack('CompleteRegistration');
      this.$gtmTrack('gtmCompleteRegistration');
      this.redirectPage();
    } else {
      this.validSignupEmail();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    goAppStore() {
      const url =
        this.device === 'ios'
          ? 'https://apps.apple.com/us/app/%E9%BB%91%E5%98%89%E5%98%89%E5%9C%8D%E6%A3%8B%E6%95%99%E5%AE%A4/id1581621835'
          : 'https://play.google.com/store/apps/details?id=com.heijiajia.tw';
      window.open(url);
    },
    goLogin() {
      this.isRedirectModalShow = false;
      this.$router.push({
        name: 'login',
      });
    },
    redirectPage() {
      if (this.isPhoneBrowser) return;
      this.debounceSec = 5;
      this.timer = setInterval(() => {
        this.debounceSec -= 1;
        if (this.debounceSec === 0) {
          clearInterval(this.timer);
          this.goLogin();
        }
      }, 1000);
    },
    async validSignupEmail() {
      if (!this.id || !this.code) {
        this.isRedirectModalShow = true;
        this.redirectPage();
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      try {
        const data = {
          id: this.id,
          code: this.code,
        };
        await this.$store.dispatch('user/validateSignupOtpEmail', data);
        this.$store.commit('env/setIsLoading', false);
        this.isValid = true;
        fbTrack('CompleteRegistration');
        this.$gtmTrack('gtmCompleteRegistration');
        this.redirectPage();
      } catch (error) {
        this.$store.commit('env/setIsLoading', false);
        this.isRedirectModalShow = true;
        this.redirectPage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-banner {
  background: url('../../assets/img/login/web_login_bg.jpg') no-repeat bottom
    center;
  background-size: cover;
  .banner-container {
    max-height: 70vh;
    padding: 0 32px;
    @media screen and (min-width: 768px) {
      padding: 0;
    }
    .banner {
      object-fit: contain;
      max-width: 100%;
      max-height: 705px;
      @media screen and (min-width: 768px) {
        object-fit: cover;
        height: calc(100vh - 30vh);
      }
    }
  }
}
.logo {
  padding: 7px 15px;
  top: 0;
  left: 0;
}
.app-title {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background: #fbe8ca;
  .btn {
    width: 240px;
  }
  @media screen and (min-width: 768px) {
    height: 30vh;
  }
}
</style>
